import React from 'react';
import QRCode from 'react-qr-code';
import { useParams } from 'react-router';

const GenerateQR = () => {
    const  {id} = useParams();
    return (
        <div>
            <h1>Generador de Código QR</h1>
            <QRCode value={id} />
        </div>
    );
};

export default GenerateQR;